import React, { useRef, useState } from 'react'
import styled, { css, Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import { H } from '@/components/Heading'
import { P } from '@/components/Paragraph'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { SwitchTransition, Transition } from 'react-transition-group'
import { useContentInProp } from '@/hooks/useContentInProp'
import { SplitText } from 'gsap/dist/SplitText'
import gsap from 'gsap'
import { breakpoints } from '@xstyled/system'
import proportion from '@/modules/utils/proportion'
import { TextNavigationLink } from '@/components/TextNavigationLink'
import Links from '@/components/Links'
import { Purpose, Teams, Innovation, Benefits } from '@/components/Join'
import { Right } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'

const Title = styled(H)`
  overflow: hidden;
`

const TabItem = styled(P)<{ active: boolean }>`
  opacity: ${(props) => (props.active ? 1 : 0.4)};
`

const CustomBreakPoint = styled(Box)`
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      min-height: 620px;
    `,
    /* eslint-enable sort-keys */
  })}
`

const getTabData = (t) => [
  {
    tab: t('dna.join_toggle1', { defaultValue: 'Our purpose' }),
    title: t('dna.join_toggle1_title', { defaultValue: 'Our purpose' }),
    url: '/our-dna/join/our-purpose',
    body: t('dna.join_toggle1_body', {
      defaultValue:
        "We build modern banking IT that propels the financial industry forward.\n\n Why? Old banking IT systems just don't cut it anymore. They're slow, error-prone, and restrict how financial providers serve and support their customers. In a world that values speed, agility and self-service, its time for the financial industry to make the impossible possible.\n\nWith Ohpen's platform, financial providers can power easy, reliable, innovative and error-free financial experiences for their customers.\n\nTogether we're reinventing financial services with cloud technology and building for the next generation. That's why we need you.",
    }),
  },
  {
    tab: t('dna.join_toggle2', { defaultValue: 'Benefits just for you' }),
    title: t('dna.join_toggle2_title', {
      defaultValue: 'Benefits just for you',
    }),
    url: '/our-dna/join/benefits',
    body: t('dna.join_toggle2_body', {
      defaultValue:
        "We build modern banking IT that propels the financial industry forward.\n\n Why? Old banking IT systems just don't cut it anymore. They're slow, error-prone, and restrict how financial providers serve and support their customers. In a world that values speed, agility and self-service, its time for the financial industry to make the impossible possible.\n\nWith Ohpen's platform, financial providers can power easy, reliable, innovative and error-free financial experiences for their customers.\n\nTogether we're reinventing financial services with cloud technology and building for the next generation. That's why we need you.",
    }),
  },
  {
    tab: t('dna.join_toggle3', { defaultValue: "Innovation, it's in our DNA" }),
    title: t('dna.join_toggle3_title', {
      defaultValue: "Innovation, it's in our DNA",
    }),
    url: '/our-dna/join/innovation',
    body: t('dna.join_toggle3_body', {
      defaultValue:
        "We build modern banking IT that propels the financial industry forward.\n\n Why? Old banking IT systems just don't cut it anymore. They're slow, error-prone, and restrict how financial providers serve and support their customers. In a world that values speed, agility and self-service, its time for the financial industry to make the impossible possible.\n\nWith Ohpen's platform, financial providers can power easy, reliable, innovative and error-free financial experiences for their customers.\n\nTogether we're reinventing financial services with cloud technology and building for the next generation. That's why we need you.",
    }),
  },
  {
    tab: t('dna.join_toggle4', { defaultValue: 'Diverse teams' }),
    title: t('dna.join_toggle4_title', { defaultValue: 'Diverse teams' }),
    url: '/our-dna/join/diverse-teams',
    body: t('dna.join_toggle4_body', {
      defaultValue:
        "We build modern banking IT that propels the financial industry forward.\n\n Why? Old banking IT systems just don't cut it anymore. They're slow, error-prone, and restrict how financial providers serve and support their customers. In a world that values speed, agility and self-service, its time for the financial industry to make the impossible possible.\n\nWith Ohpen's platform, financial providers can power easy, reliable, innovative and error-free financial experiences for their customers.\n\nTogether we're reinventing financial services with cloud technology and building for the next generation. That's why we need you.",
    }),
  },
]

const TabView = styled(Box)`
  position: relative;

  ${breakpoints({
    md: css`
      padding-bottom: ${proportion(32, 'desktop')};
    `,
    xl: css`
      padding-bottom: 32px;
    `,
  })}
`

export const Divider = styled.div`
  display: block;
  transform: scaleX(0);
  transform-origin: left;
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 48px;
  background-color: secondary;
`

const SocialPage: React.FC = () => {
  const inProp = useContentInProp()
  const { t } = useTranslation()

  const texts = {
    left: {
      link: t('dna.join_cta', { defaultValue: 'Our values' }),
      pretitle: t('dna.join_super', { defaultValue: 'Life at Ohpen' }),
      text: t('dna.join_body', {
        defaultValue:
          'At Ohpen it’s about talented people having the space to build next-generation FinTech. Why? Because we believe FinTech enables accessibility, empowerment and independence for all.',
      }),
      title: t('dna.join_title', { defaultValue: 'Join our mission' }),
      url: '/our-dna/values',
    },
  }

  const tabData = getTabData(t)
  const containerRef = useRef(null)
  const [activeTab, setActiveTab] = useState(0)

  function onClickTab(index: number) {
    return () => setActiveTab(index)
  }

  function onEnter(el) {
    gsap.fromTo(
      el.children,
      1,
      {
        opacity: 0,
      },
      {
        ease: 'sine.inOut',
        opacity: 1,
        delay: 1.1,
        stagger: {
          each: 0.1,
        },
      },
    )
  }

  const left = (
    <Text {...texts.left}>
      <Transition in={inProp} onEnter={onEnter} timeout={1000}>
        <Box ref={containerRef}>
          {tabData.map((item, index) => (
            <Box key={item.tab}>
              <TabItem
                variant="link"
                active={index === activeTab}
                onClick={onClickTab(index)}
                data-url
              >
                {item.tab}
              </TabItem>
            </Box>
          ))}
        </Box>
      </Transition>
      <TextNavigationLink
        variant="dark"
        delay={1.2}
        time={1}
        url={texts.left.url}
        text={texts.left.link}
      />
    </Text>
  )

  return (
    <>
      <Right>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="right"
            delay={1.1}
            time={1}
            url="https://ohpen.jobs.personio.com/"
            text="Vacancies"
          />
        )}
      </Right>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[
                <>
                  <Text isMobile {...texts.left}>
                    <Links
                      text={texts.left.link}
                      url={texts.left.url}
                      links={tabData}
                    />
                  </Text>
                </>,
              ]}
            >
              <Box row>
                <Box col={0.5}>
                  <Box col={0.8}>{left}</Box>
                </Box>
                <CustomBreakPoint
                  col={0.5}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box col={0.8}>
                    {activeTab === 0 && <Purpose />}
                    {activeTab === 1 && <Benefits />}
                    {activeTab === 2 && <Innovation />}
                    {activeTab === 3 && <Teams />}
                  </Box>
                </CustomBreakPoint>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default SocialPage
